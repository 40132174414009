.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select {
    width: 100%;
    /* Default width for mobile view */
    background-color: white;
    /* Background color */
    color: black;
    /* Font color */
}

@media (min-width: 600px) {
    .select {
        width: 250px;
        /* Adjusted width for tablets and larger screens */
    }
}